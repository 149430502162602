<template>
<div class="notification-container">
	<div class="d-flex header-container">
		<div class="flex-grow-1">
			<h5 class="mt-1"><font-awesome-icon icon="folder" class="mr-10"/> Daftar Takah</h5>
		</div>
		<div class="flex-grow-0 ml-10 mb-10">
			<CButton class="btn-sm text-white" color="danger" @click="showpopupModal">
				Tambah Baru
			</CButton>
		</div>
	</div>
	<hr class="bottom-devider"/>
	<div class="body-container mt-10">
		<CListGroup class="mb-10">
			<CListGroupItem href="#" v-for="(item, index) in takah" :key="index" class="flex-column mb-10 p-0" style="border-width:0">
				<div class="d-flex w-100">
					<div class="flex-grow-1">
						<CCard class="p-0 mb-0" @click="toDocList(item)">
							<CCardBody>
								<div class="d-flex w-100">
									<div class="flex-grow-0 mr-10">
										<div class="media-img-wrap">
											<font-awesome-icon icon="folder" size="2x" class="text-folder"/>
										</div>
									</div>
									<div class="flex-grow-1 text-ellipsis">
										<div class="d-flex w-100">
											<div class="mb-1 flex-grow-1">
												<div class="font-weight-bold">{{ combineNoAndName(item) }}</div>
												<div lass="help-block small">{{ item.description }}</div>
											</div>
											<div class="flex-grow-0 text-right">
												<div>
													<CBadge color="danger" shape="pill">{{ item.documentCount }}</CBadge>
												</div>
												<div class="help-block small">{{ item.createdAt }}</div>
											</div>
										</div>
									</div>
								</div>
							</CCardBody>
						</CCard>
					</div>
					<div class="flex-grow-0">
						<CDropdown class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
							<template #toggler>
								<div class="p-3 mt-3">
									<font-awesome-icon icon="cog" class="clickable text-grey"/>
								</div>
							</template>

							<CDropdownItem class="mt-2" @click="showTakahCommentPopUp(item)">
								<div class="d-flex w-100">
									<div class="flex-grow-1">
										<div class="small">
											<b>Komentar Takah</b>
										</div>
									</div>
								</div>
							</CDropdownItem>
							<CDropdownItem class="mt-2" @click="showpopupModalEdit(item)">
								<div class="d-flex w-100">
									<div class="flex-grow-1">
										<div class="small">
											<b>Ubah</b>
										</div>
									</div>
								</div>
							</CDropdownItem>
							<CDropdownItem class="mt-2" @click="deleteItem(item)">
								<div class="d-flex w-100">
									<div class="flex-grow-1">
										<div class="small">
											<b>Hapus</b>
										</div>
									</div>
								</div>
							</CDropdownItem>
						</CDropdown>
					</div>
				</div>
			</CListGroupItem>
		</CListGroup>
		<CButton v-if="showMore" @click="showMoreList" class="btn-link btn-sm w-100">
			<font-awesome-icon v-if="loader" icon="spinner" spin class="mr-10"/>
			<font-awesome-icon v-else icon="chevron-down" class="mr-10"/>
			Lihat lebih banyak
		</CButton>
	</div>

	<!-- Popup Modal & Input Form -->
	<CModal :show.sync="popupModal.isShow">
		<template #header>
			<h5 class="modal-title">{{ popupModal.modalTitle }}</h5>
		</template>
		<!-- Input Form -->
		<div class="p-1">
			<CInput
				v-model="modelForm.input.no"
				label="Nomor Takah"
				placeholder="Nomor Takah"
				:is-valid="modelForm.validate.no"
				@change="validateForm('no')" />
			<CInput
				v-model="modelForm.input.name"
				label="Nama Takah"
				placeholder="Nama Takah"
				:is-valid="modelForm.validate.name"
				@change="validateForm('name')" />
			<CTextarea
					v-model="modelForm.input.desc"
					label="Deskripsi Takah"
					placeholder="Deskripsi Takah"
					:is-valid="modelForm.validate.desc"
					@change="validateForm('desc')"
					rows="3" />
		</div>
		<template #footer>
			<CButton @click="closePopupModal" class="btn-sm">Batal</CButton>
			<CButton v-if="modelForm.editedIndex != -1" @click="updateItem" color="success" class="btn-sm" :disabled="modelForm.submitBtn.disabled">
				<font-awesome-icon v-if="modelForm.submitBtn.loader" icon="spinner" spin/> {{ modelForm.submitBtn.text }}
			</CButton>
			<CButton v-else @click="saveItem" color="success" class="btn-sm" :disabled="modelForm.submitBtn.disabled">
				<font-awesome-icon v-if="modelForm.submitBtn.loader" icon="spinner" spin/> {{ modelForm.submitBtn.text }}
			</CButton>
		</template>
	</CModal>

    <CModal :show.sync="commentTakah.showPopUp">
        <template #header>
            <h5 class="modal-title">Komentar Takah {{commentTakah.takahCode}}</h5>
        </template>
        <div v-if="commentTakah.data.length == 0" class="text-center p-3">
            <font-awesome-icon icon="info-circle" size="2x" class="text-info mb-10"/><br/>
            Tidak Ada Komentar
        </div>
        <div v-else class="mt-1">
            <CListGroup class="list-group-flush p-0 mb-10" style="max-height:400px;overflow-y: auto;">
                <CListGroupItem v-for="(item, index) in commentTakah.data" :key="index" class="flex-column align-items-start p-1">
                    <div class="d-flex w-100">
                        <CCol col="2">
                        <div class="media-img-wrap">
                            <img :src="avatar" alt="user" class="avatar-img rounded-circle img-fluid">
                        </div>
                        </CCol>
                        <CCol col="8">
                            <CRow><b>{{item.user.position}}</b></CRow>
                            <CRow><small>{{item.created_at}}</small></CRow>
                            <CRow>{{item.message}}</CRow>
                        </CCol>
                    </div>
                </CListGroupItem>
            </CListGroup>
        </div>
        <div class="doc-comment-input-container">
            <div class="d-flex w-100">
                <div class="flex-grow-1">
                    <CInput
                        v-model="commentTakah.form.message"
                        @keyup.enter="postComment"
                        class="comment-bar"
                        placeholder="Beri komentar...">
                        <template #append-content>
                                <font-awesome-icon v-if="commentTakah.postLoader" icon="spinner" spin/>
                                <font-awesome-icon @click="()=>postComment()" v-else icon="paper-plane"/>
                        </template>
                    </CInput>
                </div>
            </div>
        </div>
        <template #footer>
            <CButton @click="closeCommentTakahPopUp()" class="btn-sm">Tutup</CButton>
        </template>
    </CModal>

</div>
</template>

<script>
import {
	createNamespacedHelpers,
	mapState,
	mapGetters
} from 'vuex'
const avatar = require('@/assets/img/avatar.svg')

export default {
	names: 'Takah',
	data() {
		const popupModal = {
			isShow: false,
			modalTitle: 'Tambah Data Takah'
		}
		const modelForm = {
			editedIndex: -1,
			input: {
				takahId: null,
				name: '',
				desc: '',
				no: ''
			},
			defaultValue: {
				takahId: null,
				name: '',
				desc: '',
				no: ''
			},
			validate: {
				name: null,
				desc: null,
				no: null
			},
			submitBtn: {
				loader: false,
				text: 'Simpan',
				disabled: true
			}
		}
		const commentTakah= {
            showPopUp: false,
            takahCode: '',
            data: [],
            showMore: false,
            loader: false,
            postLoader: false,
            form: {
                takahId: null,
                message: null,
            },
            temp: {
                takahNo: null,
                takahId: null
            }
        }
		
		return {
			loader: false,
			takah: [],
			showMore: true,
			showMoreIndex: 0,
			popupModal,
			modelForm,
			commentTakah,
			avatar
		}
	},
	mounted () {
		this.getTakahList()
	},
	methods: {
		getTakahList() {
			this.loader = true
			this.$store.dispatch({
				type: 'takah/loadData',
				filter: { access: "all" },
				pagination: {
					limit: 15,
					offset: this.showMoreIndex
				}
			}).then( res => {
				if (res && res.result.length > 0) {
					const data = res.result
					data.forEach( item => {
						if(item.takah_pid == null){
							let takahRow = {
								takahNo: item.takah_no,
								takahId: item.takah_id,
								takah: item.takah,
								description: item.description,
								documentCount: item.document_count,
								createdAt: this.$moment(item.created_at).format("Y-MM-DD, HH:mm")
							}
							this.takah.push(takahRow)
						}
					})
					
					this.showMore = (data.length >= 15)?true:false
					this.showMoreIndex = this.takah.length
				} else {
					this.showMore = false
				}
			}).catch( err => {
				console.log('Failed to fetch takah data')
				console.log(err)
			}).finally( () => {
				this.loader = false
			})
		},
		showMoreList () {
			this.getTakahList()
		},
		resetList () {
			this.takah = []
			this.showMore = true
			this.showMoreIndex = 0
			this.getTakahList()
		},
		toDocList (takahProp) {
			let routePrefix = this.$route.path
			this.$router.push(routePrefix +'/files/'+ takahProp.takahId)
		},

		// CRUD
		showpopupModal () {
			this.popupModal.modalTitle = 'Tambah Data Takah'
			this.popupModal.isShow = true
		},
		showpopupModalEdit (item) {
			this.modelForm.editedIndex = item.takahId
			this.modelForm.input = {
				takahId: item.takahId,
				name: item.takah,
				desc: item.description,
				no: item.takahNo
			}
			this.validateForm('name')
			this.validateForm('desc')
			this.validateForm('no')
			this.popupModal.modalTitle = 'Ubah Data Takah ' + item.takah
			this.popupModal.isShow = true
		},
		closePopupModal () {
			this.modelForm.editedIndex = -1
			this.modelForm.input = Object.assign({}, this.modelForm.defaultValue)
			this.modelForm.validate = {
				name: null,
				desc: null
			}
			this.modelForm.submitBtn.disabled = true
			this.popupModal.isShow = false
		},
		validateForm (field) {
			if (field == 'name') {
				if (this.modelForm.input.name != '') {
					this.modelForm.validate.name = true
				} else {
					this.modelForm.validate.name = false
					return false
				}
			}
			if (field == 'desc') {
				if (this.modelForm.input.desc != '') {
					this.modelForm.validate.desc = true
				} else {
					this.modelForm.validate.desc = false
					return false
				}
			}
			if(field == 'no') {
				if (this.modelForm.input.no != '') {
					this.modelForm.validate.no = true
				} else {
					this.modelForm.validate.no = false
					return false
				}
			}

			if (this.modelForm.validate.name &&
				this.modelForm.validate.desc &&
				this.modelForm.validate.no)
				this.modelForm.submitBtn.disabled = false
		},
		saveItem () {
			this.modelForm.submitBtn = {
				loader: true,
				text: 'Memproses...',
				disabled: true
			}
			this.$store.dispatch({
				type: 'takah/store',
				formInput: this.modelForm.input
			}).then( res => {
				console.log("Result: ", res)
				this.modelForm.submitBtn = {
					loader: false,
					text: 'Simpan',
					disabled: true
				}
				this.resetList()
				setTimeout( e => {
					this.$toasted.success('Sukses menambah data!').goAway(3000)
					this.closePopupModal()
				}, 1500)
			}).catch( err => {
				console.log(err)
				this.modelForm.submitBtn = {
					loader: false,
					text: 'Simpan',
					disabled: true
				}
				this.$toasted.error('Gagal menambah data, silahkan coba kembali!').goAway(3000)
			})
		},
		updateItem () {
			this.$confirm({
				auth: false,
				message: "Anda yakin ingin mengubah data ini?",
				button: {
					no: 'Tidak',
					yes: 'Ya'
				},
				callback: confirm => {
					if (confirm) {
						this.modelForm.submitBtn = {
							loader: true,
							text: 'Memproses...',
							disabled: true
						}
						
						this.$store.dispatch({
							type: 'takah/update',
							formInput: this.modelForm.input
						}).then( res => {
							console.log("Result: ", res)
							this.modelForm.submitBtn = {
								loader: false,
								text: 'Simpan',
								disabled: true
							}
							this.resetList()
							setTimeout( e => {
								this.$toasted.success('Sukses mengubah data!').goAway(3000)
								this.closePopupModal()
							}, 1500)
						}).catch( err => {
							console.log("Error: ", err.response)
							this.modelForm.submitBtn = {
								loader: false,
								text: 'Simpan',
								disabled: true
							}
							let errorMsg = "Gagal menghapus data!"
							if (err.response.status == 401) errorMsg = "Akses terbatas untuk aksi yang dipilih!"
							this.$toasted.error(errorMsg).goAway(3000)
						})
					}
				}
			})
		},
		deleteItem (item) {
			this.$confirm({
				auth: false,
				message: "Anda yakin ingin menghapus data ini?",
				button: {
					no: 'Tidak',
					yes: 'Ya'
				},
				callback: confirm => {
					if (confirm) {
						this.$store.dispatch({
							type: 'takah/destroy',
							formInput: item
						}).then( res => {
							console.log("Result: ", res)
							this.resetList()
							setTimeout( e => {
								this.$toasted.success('Sukses menghapus data!').goAway(3000)
							}, 1500)
						}).catch( err => {
							let errorMsg = "Gagal menghapus data!"
							if (err.response.status == 400) errorMsg = "Akses terbatas untuk aksi yang dipilih!"
							this.$toasted.error(errorMsg).goAway(3000)
						})
					}
				}
			})
		},
		combineNoAndName(item) {
			if(item.takahNo == null || item.takahNo == '') {
				return item.takah
			} else {
				return item.takahNo + " - " + item.takah 
			}
		},

        showTakahCommentPopUp(item) {
            this.commentTakah.takahCode= item.takahNo
            // get Takah comment

            this.commentTakah.data = []
            this.commentTakah.form.takahId = item.takahId

            this.commentTakah.temp.takahNo = item.takahNo
            this.commentTakah.temp.takahId = item.takahId

            this.getTakahComment(item.takahId)
            this.commentTakah.showPopUp= true
        },

        getTakahComment(param){
            this.$store.dispatch(
                {
                    type: 'takah/getTakahChatList',
                    pagination: {
                        limit: 5,
                        offset: 0
                    },
                    filter: {
                        takahId: param
                    }
                }
            ).then( res => {
                let items = res.result
                if (items.length > 0) {
                    items.forEach((item) => {
                        this.commentTakah.data.push(item)
                    })
                } else {
                    this.commentTakah.showMore = false
                }
            }).finally( () => {
                this.commentTakah.loader = false
            })
        },

        postComment(){
            this.commentTakah.takahCode = this.commentTakah.form.message
            if (this.commentTakah.form.message == '')
                return false

            this.commentTakah.postLoader = true
            this.commentTakah.takahCode = 'ok'
            this.$store.dispatch({
                type: 'takah/postTakahComment',
                formInput: this.commentTakah.form
            }).then( res => {
                console.log("Result: ",res)
            }).catch( err => {
                console.log(err)
                this.$toasted.error('Gagal menambah komentar, silahkan coba kembali!').goAway(3000)
            }).finally( () => {

                setTimeout(() => {
					this.getTakahComment(this.commentTakah.temp)
					this.commentTakah.postLoader = false
                    this.commentTakah.form.message = ''
                    this.closeCommentTakahPopUp(true)
				}, 500)
            })

        },

        closeCommentTakahPopUp(restart) {
            this.commentTakah.showPopUp= false
            if (restart) {
                this.showTakahCommentPopUp(this.commentTakah.temp)
            }
        }
    },
}
</script>

<style scoped lang="scss">
.mb-0 {
	margin-bottom: 0;
}
.text-folder {
	color: rgb(240, 196, 85);
}
span.vue-fontawesome > span.v-time-ago__text{
	font-size: 80%!important;
	margin-right: 10px;
}
</style>